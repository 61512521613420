
import Vue from "vue";
import availableCountries from "@/data/countries";
import LanguageGlobalSelectionModule from "@/store/modules/LanguageGlobalSelectionModule";
import { getModule } from "vuex-module-decorators";
import Country from "@/models/Country";
import LanguageSpecificWelcomeMessage from "@/models/moduleArea/LanguageSpecificWelcomeMessage";
import i18n from "@/i18n";

export default Vue.extend({
  name: "SelectWelcomeMessage",

  props: ["welcomeMessages"],

  data() {
    return {
      dialog: false,

      form: JSON.parse(JSON.stringify(this.welcomeMessages)),
      valid: true,

      rules: {
        required: (value: string) =>
          !!value || i18n.t("alerts.common.requiredField"),
      },

      countries: availableCountries,

      languageGlobalSelectionModule: getModule(
        LanguageGlobalSelectionModule,
        this.$store
      ),

      selectedCountry: -1,
      indexSelectedLanguage: -1,
      selectedCountryDetails: new Country("de"),
      showTransitions: false,
      selectWelcomeMessageKey: 0,
    };
  },

  mounted() {
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
  },

  destroyed() {
    this.resetForm();
  },

  watch: {
    welcomeMessages() {
      this.form = JSON.parse(JSON.stringify(this.welcomeMessages));
    },

    selectedCountry() {
      const country = this.countries.find(
        (elem, index) => index == this.selectedCountry
      );
      this.selectedCountryDetails = country ? country : new Country();
      this.setIndexSelectedLanguage();
    },
  },

  created() {
    this.selectedCountry = this.countries.findIndex(
      (e) => e.locale === this.languageGlobalSelectionModule.selectedLanguage
    );
    this.selectedCountryDetails = this.countries[this.selectedCountry];
    this.setIndexSelectedLanguage();
  },

  methods: {
    close() {
      this.selectWelcomeMessageKey++;
      this.dialog = false;
      this.resetForm();
    },

    resetForm() {
      const formElem: any = this.$refs.form;
      formElem?.resetValidation();
      this.form = JSON.parse(JSON.stringify(this.welcomeMessages));
    },

    setIndexSelectedLanguage() {
      this.indexSelectedLanguage = this.form.findIndex(
        (element: LanguageSpecificWelcomeMessage) =>
          element.language === this.selectedCountryDetails.locale
      );
    },

    onSaveWelcomeMessages() {
      if (
        this.form.some(
          (el: LanguageSpecificWelcomeMessage) => el.welcomeMessage === ""
        )
      ) {
        alert(i18n.t("alerts.common.welcomeMessageRequired"));
      } else {
        this.$emit("onSaveWelcomeMessages", this.form);
        this.close();
      }
    },
  },
});
